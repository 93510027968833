<template>
    <base-content>
        <template #button>
            <a-button type="primary" @click="$refs.MaterialModal.edit(0)">新增</a-button>
        </template>
        <div style="width:100%;display:flex;flex-wrap: wrap">
            <div style="display:flex;flex-grow:2;align-items:center;">
                <span style="margin-left:0px;flex-shrink:0">物料名称：</span>
                <a-input v-model:value="material_name" style="flex-grow:1"></a-input>
            </div>
            <div style="display:flex;align-items:center;">
                <span style="margin-left:12px;flex-shrink:0">物料编号：</span>
                <a-input-group compact>
                    <a-input v-model:value="prefix" style="width:40px;text-align:center" :maxlength="2" />
                    <a-input style="width:20px;border-left:0;padding:4px 0;text-align:center" placeholder="-" disabled />
                    <a-input v-model:value="suffix" style="width:60px; text-align:center;border-left:0" :maxlength="4" />
                </a-input-group>
            </div>
            <div style="display:flex;flex-grow:3;align-items:center;">
                <span style="margin-left:12px;flex-shrink:0">分类：</span>
                <a-tree-select style="flex-grow:1" v-model:value="category_id" :tree-data="category_tree" :replaceFields="{ children: 'children', title: 'name', key: 'id', value: 'id' }" treeDefaultExpandAll />
            </div>
            <div style="display:flex;align-items:center;">
                <span style="margin-left:12px;flex-shrink:0">标签：</span>
                <a-input-group compact>
                    <a-select v-model:value="tags_mode">
                        <a-select-option value="or">或含</a-select-option>
                        <a-select-option value="and">并含</a-select-option>
                    </a-select>
                    <a-select :maxTagCount="1" v-model:value="tags" mode="multiple" :options="tags_options" style="min-width:120px" />
                </a-input-group>
            </div>
            <a-button style="margin-left:12px;" @click="search">检索</a-button>
        </div>
        <a-table style="margin:10px 0px;" :columns="table_columns" :data-source="table_data" rowKey="id" :pagination="false" bordered size="middle">
            <template #image="scope">
                <span>{{ JSON.parse(scope.record.images).length ? `共${JSON.parse(scope.record.images).length}张` : '无' }}</span>
            </template>
            <template #operation="scope">
                <a-button type="link" size="small" @click="$refs.MaterialModal.show(scope.record.id)" v-if="permit('view')">查看</a-button>
                <a-button type="link" size="small" @click="$refs.MaterialModal.edit(scope.record.id)" v-if="permit('edit')" :disabled="!!scope.record.delete_flag">修改</a-button>
                <a-button type="link" size="small" @click="$refs.DataLog.show(scope.record.id)" v-if="permit('admin')">管理</a-button>
                <a-button type="link" size="small" @click="$refs.MaterialModal.show(scope.record.id)" v-if="permit('delete')" :disabled="!!scope.record.delete_flag">删除</a-button>
            </template>
        </a-table>
        <a-pagination style="display:flex;justify-content:center;" show-size-changer v-model:current="page" v-model:pageSize="perpage" :total="data_count" @change="search" @showSizeChange="search" :pageSizeOptions="['10', '15', '20', '50', '100']" v-if="data_count" />
        <MaterialModal ref="MaterialModal" @finish="search" />
        <DataLog ref="DataLog" data_table="material" />
    </base-content>
</template>

<script>
import DataLog from '../components/modal/DataLog';
import MaterialModal from '../components/modal/Material';
export default {
    components: {
        DataLog,
        MaterialModal,
    },
    data() {
        return {
            permissions: [],
            table_columns: [
                { title: 'ID', dataIndex: 'id', width: 50, align: 'center' },
                { title: '物料编码', dataIndex: 'material_code', align: 'center', width: 100 },
                { title: '名称', dataIndex: 'name' },
                { title: '所属分类', dataIndex: 'category_name' },
                { title: '操作', align: 'center', width: 200, slots: { customRender: 'operation' } },
            ],
            category_tree: [],
            category_id: 0,
            material_name: '',
            prefix: '',
            suffix: '',
            tags: [],
            tags_mode: 'or',
            tags_options: [],
            page: 1,
            perpage: 10,
            table_data: [],
            data_count: 0,
        };
    },
    watch: {},
    mounted() {
        let flag = [false, false, false];
        this.permissions = this.$store.getters.operationPermission('material');
        this.$api('getCategoryTree').then((res) => {
            this.category_tree = res.category_tree;
            flag[0] = true;
        });
        this.$api('getCategoryList', { parent_id: -1 }).then((res) => {
            this.category_list = res.category_list;
            flag[1] = true;
        });
        this.$api('getMaterialTagList').then((res) => {
            res.material_tag_list.forEach((item) => {
                this.tags_options.push({ label: item, value: item });
            });
            flag[2] = true;
        });
        let f = () => {
            if (flag[0] && flag[1] && flag[2]) {
                this.search();
            } else {
                setTimeout(f, 15);
            }
        };
        f();
    },
    methods: {
        _10to32(num10) {
            num10 = parseInt(num10);
            if (num10 < 0 || num10 > 1048575) {
                throw new Error('用于转换的10进制数太小或太大，仅限0~1048575，而你传入了：' + num10);
            }
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            let str32 = '';
            while (true) {
                str32 = map[num10 % 32] + str32;
                num10 = parseInt(num10 / 32);
                if (num10 == 0) {
                    return ('000' + str32).slice(-4);
                }
            }
        },
        _32to10(str32) {
            str32 = String(str32);
            if (str32.length > 4 || str32.length < 1) {
                throw new Error('用于转换的32进制数太小或太大，仅限0000~YYYY，而你传入了：' + str32);
            }
            let num10 = 0;
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            for (let i = 0; i < str32.length; i++) {
                let index = map.indexOf(str32[i]);
                if (index == -1) {
                    throw new Error('用于转换的32进制数字符不符，仅限0123456789ABCEFGHJKLMNPQRSTUVWXY，而你传入了：' + str32[i]);
                }
                num10 += index * 32 ** (str32.length - 1 - i);
            }
            return num10;
        },
        search() {
            let f = (v) => {
                return '0123456789ABCEFGHJKLMNPQRSTUVWXY'.indexOf(v) != -1;
            };
            let conditions = {
                flag: {
                    material_name: !!this.material_name.replace(' ', ''),
                    prefix: this.prefix.replace(' ', '').length == 2 && this.prefix.split('').every(f),
                    suffix: this.suffix.replace(' ', '').length == 4 && this.suffix.split('').every(f),
                    tags: !!this.tags.length,
                    admin: true,
                },
                material_name: this.material_name,
                prefix: this.prefix,
                suffix: this.suffix,
                category_id: this.category_id,
                tags_mode: this.tags_mode,
                tags: this.tags,
            };
            if (conditions.flag.suffix) conditions.suffix = this._32to10(conditions.suffix);
            conditions = JSON.stringify(conditions);
            this.$api('searchMaterial', { conditions, page: this.page, perpage: this.perpage }).then((res) => {
                this.data_count = res.count;
                this.table_data = res.material_list;
                for (let i = 0; i < this.table_data.length; i++) {
                    this.table_data[i].material_code = this.table_data[i].prefix + '-' + this._10to32(this.table_data[i].suffix);
                    this.category_list.forEach((item) => {
                        if (item.id == this.table_data[i].category_id) this.table_data[i].category_name = item.name;
                    });
                }
            });
        },
        permit(permission) {
            return this.permissions.indexOf(permission) != -1;
        },
    },
};
</script>

<style></style>
