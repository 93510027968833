<template>
    <div>
        <a-upload list-type="picture-card" :fileList="file_list" :multiple="multiple" :customRequest="upload" @preview="preview" :remove="remove" :beforeUpload="before" :disabled="disabled">
            <div v-if="file_list.length < maxLength && !disabled">
                <plus-outlined />
                <div class="ant-upload-text">上传图片</div>
            </div>
            <div v-if="file_list.length == 0 && disabled">
                <PictureOutlined />
                <div class="ant-upload-text">暂无图片</div>
            </div>
        </a-upload>
        <a-modal :visible="preview_visible" :footer="null" @cancel="cancel" :closable="false">
            <img style="width: 100%" :src="preview_image" />
        </a-modal>
    </div>
</template>
<script>
import { PlusOutlined, PictureOutlined } from '@ant-design/icons-vue';
export default {
    components: {
        PictureOutlined,
        PlusOutlined,
    },
    props: {
        maxLength: {
            type: Number,
            default: 15,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        value: {
            type: Array,
            default: [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value() {
            this.change();
        },
    },
    data() {
        return {
            prefix: this.$store.state.backend_url + '/image/',
            preview_visible: false,
            preview_image: '',
            file_list: [],
            precount: 0,
        };
    },
    methods: {
        // 组件创建时根据传入的value初次生成file_list
        // 或父级组件传的值value改变时，重新生成file_list
        change() {
            let t = [];
            this.value.forEach((i) => {
                t.push({
                    uid: '-' + (t.length + 1),
                    name: i,
                    status: 'done',
                    url: this.prefix + i,
                });
            });
            this.file_list = t;
            this.precount = this.value.length;
        },
        cancel() {
            this.preview_visible = false;
        },
        preview(file) {
            this.preview_image = file.url;
            this.preview_visible = true;
        },
        before(e) {
            if (['image/jpeg', 'image/png'].indexOf(e.type) == -1) {
                this.$message.error(`仅能上传图片文件`);
                return false;
            }
        },
        upload(e) {
            if (this.precount < this.maxLength) {
                this.precount++;
                this.$api('uploadImage', { file: e.file }).then((res) => {
                    if (res.result != '上传成功') {
                        this.$message.error('图片上传失败，请重试');
                    } else {
                        if (this.value.indexOf(res.file_name) == -1) {
                            this.file_list.push({
                                uid: '-' + (this.file_list.length + 1),
                                name: res.file_name,
                                status: 'done',
                                url: this.prefix + res.file_name,
                            });
                            this.update();
                        } else {
                            this.$message.error('图片重复，请重新上传');
                            this.precount--;
                        }
                    }
                });
            } else {
                this.$message.error(`最多只能上传${this.maxLength}张图片`);
            }
        },
        remove(e) {
            this.precount--;
            for (let i = 0; i < this.file_list.length; i++) {
                if (this.file_list[i].uid == e.uid) {
                    this.file_list.splice(i, 1);
                    break;
                }
            }
            this.update();
        },
        update() {
            let update = [];
            this.file_list.forEach((item) => {
                update.push(item.name);
            });
            this.$emit('update:value', update);
        },
    },
    mounted() {
        this.change();
    },
};
</script>
<style></style>
