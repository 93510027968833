<template>
    <a-modal v-model:visible="visible" :title="title" width="588px" :footer="false" destroyOnClose>
        <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 4, offset: 1 }" :wrapper-col="{ span: 15 }">
            <a-form-item label="物料名称" name="name">
                <a-input v-model:value="form.name" :disabled="mode != 'edit'" :maxlength="255" />
            </a-form-item>
            <a-form-item label="物料分类" name="category_id">
                <a-tree-select v-model:value="form.category_id" :tree-data="category_tree" :replaceFields="{ children: 'children', title: 'name', key: 'id', value: 'id' }"  :disabled="mode != 'edit'" @select="changeCategory" />
            </a-form-item>
            <a-form-item label="计量单位" name="other_unit">
                <a-input-group compact>
                    <a-select v-model:value="form.unit" :style="{ width: form.unit == '其他' ? '35%' : '100%' }" :options="unit_list" @select="changeUnit" :disabled="mode != 'edit'" />
                    <a-input v-model:value="form.other_unit" style="width:65%;" :hidden="form.unit != '其他'" @blur="$refs.form.validateField(['other_unit'])" :disabled="mode != 'edit'" />
                </a-input-group>
            </a-form-item>
            <a-form-item label="物料编码" name="material_code">
                <a-input-group compact>
                    <a-input v-model:value="form.prefix" style="width:calc(40% - 10px);text-align:center" disabled v-if="mode != 'edit'" />
                    <a-select v-model:value="form.prefix" :options="prefix_list" style="width:calc(40% - 10px);text-align:center" v-else @change="getSuffix" />
                    <a-input style="width:20px;border-left:0;padding:4px 0;text-align:center" placeholder="-" disabled />
                    <a-input v-model:value="form.suffix" :style="{ width: mode != 'edit' ? 'calc(60% - 8px)' : 'calc(60% - 100px)', textAlign: 'center', borderLeft: '0' }" :maxlength="4" :disabled="mode != 'edit' || get_suffix_loading" @change="changeSuffix" />
                    <a-button style="width:93px" v-if="mode == 'edit'" @click="getSuffix" :loading="get_suffix_loading">{{ get_suffix_loading ? '' : '自动获取' }}</a-button>
                </a-input-group>
            </a-form-item>
            <a-form-item label="标签" name="tags">
                <a-select v-model:value="form.tags" mode="tags" :options="tag_list" :maxTagTextLength="15" :disabled="mode != 'edit'" />
            </a-form-item>
            <a-form-item label="规格" name="specification">
                <a-input v-model:value="form.specification" :disabled="mode != 'edit'" :maxlength="255" />
            </a-form-item>
            <a-form-item label="材质" name="texture">
                <a-input v-model:value="form.texture" :disabled="mode != 'edit'" :maxlength="255" />
            </a-form-item>
            <a-form-item label="备注" name="remark">
                <a-textarea v-model:value="form.remark" :disabled="mode != 'edit'" :maxlength="1023" :autoSize="{ minRows: 2, maxRows: 5 }" />
            </a-form-item>
            <a-form-item label="图片" name="images">
                <ImageUpload v-model:value="form.images" :disabled="mode != 'edit'" />
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 5 }">
                <a-button type="primary" @click="submit" v-if="edit" style="margin-right:20px">提交</a-button>
                <a-button @click="visible = false">关闭</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
/**
 * 使用方法，为该组件添加ref属性，然后调用该组件的show或edit方法
 */
import ImageUpload from '../ImageUpload';
export default {
    name: 'Material',
    components: { ImageUpload },
    data() {
        return {
            mode: 'show',
            visible: false,
            material_id: 0,
            title: '',
            category_tree: [],
            unit_list: [],
            prefix_list: [],
            tag_list: [],
            form: {
                name: '',
                category_id: '',
                images: [],
                // 用于选择，当选择其他时展示输入框，输入框的value值与other_unit绑定，同时选择已有的单位时会将值同步更新到other_unit
                unit: '',
                // 用于检验、提交的值，选择已有的单位（即在下拉框中更改上方unit的值）时会同步至other_unit
                other_unit: '',
                tags: [],
                prefix: '',
                suffix: '',
                specification: '',
                texture: '',
                remark: '',
                material_code: '',
            },
            rules: {
                name: [{ required: true, message: '请输入物料名称', trigger: 'blur' }],
                other_unit: [{ required: true, message: '请选择或输入计量单位', trigger: ['change', 'blur'] }],
                category_id: [{ required: true, type: 'integer', min: 1, message: '请选择物料分类', trigger: 'change' }],
                material_code: [
                    { required: true, message: '请输入物料编码', trigger: 'change' },
                    { pattern: /[0123456789ABCEFGHJKLMNPQRSTUVWXY]{6}/, message: '后缀仅支持以下字符：0123456789ABCEFGHJKLMNPQRSTUVWXY', trigger: 'blur' },
                ],
            },
            get_suffix_loading: false,
        };
    },
    watch: {
        visible(value) {
            if (value) {
                // 获取分类树
                this.$api('getCategoryTree').then((res) => {
                    this.category_tree = res.category_tree;
                    this.category_tree[0].disabled = true;
                });
                if (this.mode == 'edit') {
                    // 获取标签列表
                    this.$api('getMaterialTagList').then((res) => {
                        this.tag_list = [];
                        res.material_tag_list.forEach((item) => {
                            this.tag_list.push({ label: item, value: item });
                        });
                    });
                    // 获取计量单位列表
                    this.$api('getMaterialUnitList').then((res) => {
                        this.unit_list = [];
                        res.material_unit_list.forEach((item) => {
                            this.unit_list.push({ label: item, value: item });
                        });
                        this.unit_list.push({ label: '其他...', value: '其他' });
                    });
                }
                this.form = {
                    name: '',
                    category_id: '',
                    images: [],
                    unit: '',
                    other_unit: '',
                    tags: [],
                    prefix: '',
                    suffix: '',
                    specification: '',
                    texture: '',
                    remark: '',
                    material_code: '',
                };
            }
        },
    },
    mounted() {},
    methods: {
        _10to32(num10) {
            num10 = parseInt(num10);
            if (num10 < 0 || num10 > 1048575) {
                console.error('用于转换的10进制数太小或太大，仅限0~1048575，而你传入了：' + num10);
                return;
            }
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            let str32 = '';
            while (true) {
                str32 = map[num10 % 32] + str32;
                num10 = parseInt(num10 / 32);
                if (num10 == 0) {
                    return ('0000' + str32).slice(-4);
                }
            }
        },
        _32to10(str32) {
            str32 = String(str32);
            if (str32.length > 4 || str32.length < 1) {
                console.error('用于转换的32进制数太小或太大，仅限0000~YYYY，而你传入了：' + str32);
                return;
            }
            let num10 = 0;
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            for (let i = 0; i < str32.length; i++) {
                let index = map.indexOf(str32[i]);
                if (index == -1) {
                    console.error('用于转换的32进制数字符不符，仅限0123456789ABCEFGHJKLMNPQRSTUVWXY，而你传入了：' + str32[i]);
                    return;
                }
                num10 += index * 32 ** (str32.length - 1 - i);
            }
            return num10;
        },
        show: function(material_id) {
            this.mode = 'show';
            this.material_id = material_id;
            this.visible = true;
            this.getData();
        },
        edit: function(material_id) {
            this.mode = 'edit';
            this.material_id = material_id;
            this.visible = true;
            if (material_id == 0) {
                this.title = '新增物料信息';
            } else {
                this.getData();
            }
        },
        getData() {
            this.$api('getMaterialDetail', { material_id: this.material_id }).then((res) => {
                this.form = {
                    name: res.material_detail.name,
                    category_id: res.material_detail.category_id,
                    images: JSON.parse(res.material_detail.images),
                    unit: res.material_detail.unit,
                    other_unit: res.material_detail.unit,
                    tags: res.material_detail.tags,
                    prefix: res.material_detail.prefix,
                    suffix: this._10to32(res.material_detail.suffix),
                    specification: res.material_detail.specification,
                    texture: res.material_detail.texture,
                    remark: res.material_detail.remark,
                    material_code: res.material_detail.prefix + this._10to32(res.material_detail.suffix),
                };
                this.title = `正在${{ show: '查看', edit: '修改' }[this.mode]} 【${res.material_detail.name}】`;
            });
        },
        // 自动获取后缀
        async getSuffix() {
            if (this.form.prefix == '') {
                this.$message.error('请先选择编码前缀');
                return;
            }
            this.get_suffix_loading = true;
            // 每次询问的步长
            let step = 2500;
            this.form.suffix = '';
            // 优先询问仅使用数字的四位字符，即0000~9999
            for (let number_suffix = 0; number_suffix < 10000; number_suffix += step) {
                let query_list = [];
                let end = number_suffix + step < 10000 ? number_suffix + step : 10000;
                for (let i = number_suffix; i < end; i++) {
                    query_list.push(this._32to10(i));
                }
                let res = await this.$api('checkMaterialSuffix', { prefix: this.form.prefix, suffix_list: JSON.stringify(query_list) });
                if (res.result) {
                    this.form.suffix = this._10to32(res.valid_suffix);
                    break;
                }
            }
            // 全循环
            if (!this.form.suffix) {
                for (let suffix = 0; suffix < 1048576; suffix += step) {
                    let query_list = [];
                    let end = suffix + step < 1048576 ? suffix + step : 1048576;
                    for (let i = suffix; i < end; i++) {
                        query_list.push(i);
                    }
                    let res = await this.$api('checkMaterialSuffix', { prefix: this.form.prefix, suffix_list: JSON.stringify(query_list) });
                    if (res.result) {
                        this.form.suffix = this._10to32(res.valid_suffix);
                        break;
                    }
                }
            }
            this.get_suffix_loading = false;
            if (this.form.suffix) {
                this.$message.success('已自动获取后缀，若不合适请更改');
                this.changeSuffix();
            } else {
                this.$message.error('该前缀下已无可用后缀！');
            }
        },
        // 手动或自动修改后缀后，触发此函数，用于更新material_code
        changeSuffix() {
            if (this.form.prefix.length == 2 && this.form.suffix.length == 4) {
                this.form.material_code = this.form.prefix + this.form.suffix;
                this.$refs.form.validateField(['material_code']);
            }
        },
        // 激活表单对该字段的校验，并同步unit到other_unit，原因在data.form中相关变量处已具体说明
        changeUnit(value) {
            this.form.other_unit = value == '其他' ? '' : value;
            this.$refs.form.validateField(['other_unit']);
        },
        /**
         * 更改完分类以后，筛选出可用于选择的分类前缀
         */
        changeCategory(value) {
            // 找到选定分类在分类树中的堆栈或者说路径，是一个一维数组，从上级到下级排列
            let findNode = (target_id, temp_tree = this.category_tree, stack = []) => {
                for (let i = 0; i < temp_tree.length; i++) {
                    stack.push(temp_tree[i]);
                    if (temp_tree[i].id == target_id) {
                        return stack;
                    } else if (findNode(target_id, temp_tree[i].children, stack) === false) {
                        stack.pop();
                    } else {
                        return stack;
                    }
                }
                return false;
            };
            // 遍历给定树的子节点，将所有节点转为列表
            let traverseNode = (tree, list = []) => {
                tree.forEach((item) => {
                    list.push(item);
                    traverseNode(item.children, list);
                });
                return list;
            };
            let list = findNode(value);
            list = list.concat(traverseNode(list[list.length - 1].children));
            // 重置可选前缀列表
            this.prefix_list = [];
            list.forEach((item) => {
                item.id == value ? (this.form.prefix = item.prefix) : '';
                item.prefix ? this.prefix_list.push({ label: `${item.prefix}[${item.name}]`, value: item.prefix }) : '';
            });
            // 进行提示，或自动选择前缀
            if (!this.form.prefix && !this.prefix_list.length) {
                this.$message.error('该分类父级或子级均无可用前缀，请重新选择');
            } else if (!this.form.prefix && this.prefix_list.length) {
                this.$message.warn('当前分类未使用前缀，请手动选择');
            } else {
                this.getSuffix();
            }
        },
        async submit() {
            this.$refs.form.validate().then(async (res) => {
                if (!res) return;
                let data = {
                    name: this.form.name,
                    category_id: this.form.category_id,
                    unit: this.form.other_unit,
                    prefix: this.form.prefix,
                    suffix: this._32to10(this.form.suffix),
                    texture: this.form.texture,
                    specification: this.form.specification,
                    images: JSON.stringify(this.form.images),
                    tags: JSON.stringify(this.form.tags),
                    remark: this.form.remark,
                };
                if (this.material_id == 0) {
                    res = await this.$api('addMaterial', data);
                } else {
                    data.material_id = this.material_id;
                    res = await this.$api('editMaterial', data);
                }
                if (res.result) {
                    this.$message.success('保存成功');
                    this.visible = false;
                    this.$emit('finish');
                } else {
                    this.$message.error(res.message);
                }
            });
        },
    },
};
</script>

<style></style>
